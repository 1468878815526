import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Jules Kaplan was a student at `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{` at the same time that `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart Lauten`}</a>{` was. Jules was Rikkart’s friend since childhood, The `}<a parentName="p" {...{
        "href": "/Kaplan%20Family",
        "title": "Kaplan Family"
      }}>{`Kaplan Family`}</a>{` being very close to the `}<a parentName="p" {...{
        "href": "/Lauten%20Family",
        "title": "Lauten Family"
      }}>{`Lauten Family`}</a>{`. Born of a high class family, Jules never let his station stop him from shirking his responsibilities and exploring the streets of `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{`, often getting into trouble along the way. His arrogance, however, kept him from being able to fit it well amongst peers of a lower class.`}</p>
    <p>{`When he came of age, his family coerced him into attending school at the Basentia, although he did not wish to. Although his studies initially were geared towards Politics, he found that he had some `}<a parentName="p" {...{
        "href": "/Affinity",
        "title": "Affinity"
      }}>{`Affinity`}</a>{` for Magic, and switched to `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Luminancy`}</a>{`, studying to become an `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronist`}</a>{` instead.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      